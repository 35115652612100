import "./App.css";
import MainPage from "./components/MainPage";


function App() {
  return (
    <div>
      <MainPage/>
    </div>
  );
}

export default App;
